/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useState } from "react";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import Select from "react-select";
import RegisterVendor from "../../services/Register";
import { auth } from "../../services/Firebase";
import api from "../../services/api";

function Register() {
  const [vendorId, setVendorId] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [fee, setFee] = useState(null);
  const [role, setRole] = useState('');
  const [producerList, setProducerList] = useState([]);
  const [producer, setProducer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    async function fetchProducerList() {
      try {
          const token = await auth.currentUser.getIdToken(true);
          const response = await api.get('/findAllVendor', {
            params: { token: token },
          });

          setProducerList(response.data.map((item) => ({ value: item.vendor, label: item.vendor })));

        } catch (error) {
          console.log(error);
      }
    }
    fetchProducerList();
    document.body.classList.toggle("register-page");
    return function cleanup() {
      document.body.classList.toggle("register-page");
    };
  }, []);
  
  const handleSelection = (selection) => {
    setRole(selection);
  };

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {

      const register = new RegisterVendor().getInstance();
      const user = {
        vendorId: vendorId,
        producer: producer?.value,
        email: email,
        role: role,
        fee: fee,
        password,
        username
      }

      Object.keys(user).forEach(key => {
        if (user[key] === undefined || user[key] === null || user[key] === '') {
            delete user[key];
        }
    });
    
      await register.createUser(user);
      setIsLoading(false);
      alert('Cadastro realizado com sucesso');
    } catch (error) {
      setIsLoading(false);
      alert('Erro ao cadastrar');
      console.log(error);
    }
    
  }, [vendorId, email, role, producer, username, password, fee]);

  return (
    <div className="register-page">
      <Container>
        <Row>
          <Col className="ml-auto" lg="5" md="5">
            <div className="info-area info-horizontal mt-5">
              <div className="icon icon-primary">
                <i className="nc-icon nc-tv-2" />
              </div>
              <div className="description">
                <h5 className="info-title">Nome</h5>
                <p className="description">
                  Nome da conta que deseja cadastrar. <b>Deve ser único.</b> Se for produtor deve ser igual ao cadastrado no Guru.
                </p>
              </div>
            </div>
            <div className="info-area info-horizontal">
              <div className="icon icon-primary">
                <i className="nc-icon nc-html5" />
              </div>
              <div className="description">
                <h5 className="info-title">Apelido</h5>
                <p className="description">
                  Apelido que será mostrado no dashboard. Dica: deixe em branco para usar o nome da conta.
                </p>
              </div>
            </div>
            <div className="info-area info-horizontal">
              <div className="icon icon-info">
                <i className="nc-icon nc-atom" />
              </div>
              <div className="description">
                <h5 className="info-title">Email</h5>
                <p className="description">
                  Email para realizar o login. <b>Deve ser único.</b>
                </p>
              </div>
            </div>
            <div className="info-area info-horizontal">
              <div className="icon icon-info">
                <i className="nc-icon nc-atom" />
              </div>
              <div className="description">
                <h5 className="info-title">Senha</h5>
                <p className="description">
                  Se não for informada o sistema gera uma senha que (um dia) será enviada por email automaticamente.
                </p>
              </div>
            </div>
          </Col>
          <Col className="mr-auto" lg="4" md="6">
            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">Register</CardTitle>
                <div className="social">
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fa fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="dribbble">
                    <i className="fa fa-dribbble" />
                  </Button>
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fa fa-facebook-f" />
                  </Button>
                  <p className="card-description">* são campos obrigatórios</p>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="" className="form" method="">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      placeholder=" * Nome..." 
                      type="text" 
                      value={vendorId}
                      onChange={(e) => setVendorId(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-circle-10" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Apelido..." type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                     />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      placeholder=" * Email..." 
                      type="email" 
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      placeholder="Senha..." 
                      type="password" 
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                  <FormGroup check className="text-left">
                    <ButtonGroup className="d-flex justify-content-center">
                      <Button
                        className="btn-round"
                        color="info"
                        outline
                        type="button"
                        onClick={(e) => handleSelection('Producer')}
                      >
                        Produtor
                      </Button>
                      <Button
                        className="btn-round"
                        color="info"
                        outline
                        type="button"
                        onClick={(e) => handleSelection('Coproducer')}
                      >
                        Coprodutor
                      </Button>
                      <Button
                        className="btn-round"
                        color="info"
                        outline
                        type="button"
                        onClick={(e) => handleSelection('Support')}
                      >
                        Suporte
                      </Button>
                    </ButtonGroup>
                    {
                      (role === 'Coproducer' || role === 'Producer') && (
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-money-coins" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input 
                            placeholder="* Taxa Zeeplo..." 
                            type="number" 
                            value={fee}
                            onChange={(e) => setFee(e.target.value)}
                          />
                        </InputGroup>
                      )
                    }
                    {
                      (role === 'Coproducer' || role === 'Support') && (
                        <FormGroup>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            placeholder="Produtor"
                            name="singleSelect"
                            value={producer}
                            onChange={(e) => setProducer(e)}                            
                            options={producerList}
                          />
                        </FormGroup>
                      )
                    }
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-round"
                  color="info"
                  disabled={isLoading}
                  onClick={(e) => submitHandler(e)}
                >
                  {isLoading ? "Loading..." : "Cadastrar"}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/jan-sendereks.jpg")})`
        }}
      />
    </div>
  );
}

export default Register;
