import api from "./api"

class Register {

  createUser = async (user) => {
    const response = await api.post("/createUser", user);
    return response.data;
  }
}

export default class Singleton {
  constructor() {
    if (!Singleton.instance) {
      Singleton.instance = new Register();
    }
  }

  getInstance() {
    return Singleton.instance;
  }
}